import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3264.000000 3086.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,3086.000000) scale(0.100000,-0.100000)">
					<path d="M15980 29979 c-306 -6 -665 -22 -755 -34 -23 -3 -115 -12 -205 -20
-417 -37 -744 -78 -1105 -140 -49 -9 -117 -20 -150 -26 -241 -41 -676 -135
-985 -213 -2485 -629 -4742 -1882 -6540 -3631 -1060 -1031 -1874 -2078 -2567
-3300 -157 -277 -398 -745 -520 -1010 -23 -49 -57 -124 -76 -165 -19 -41 -46
-103 -61 -137 -14 -35 -37 -86 -50 -115 -54 -119 -246 -605 -321 -813 -242
-671 -449 -1407 -579 -2055 -47 -238 -97 -518 -115 -650 -5 -36 -19 -128 -30
-205 -55 -385 -90 -747 -123 -1250 -17 -269 -17 -1301 0 -1570 33 -503 68
-865 123 -1250 11 -77 25 -169 30 -205 13 -98 27 -178 74 -435 128 -697 336
-1474 581 -2160 25 -71 56 -159 69 -195 144 -403 402 -998 642 -1480 207 -416
375 -721 598 -1085 429 -701 901 -1344 1434 -1955 1281 -1466 2802 -2644 4496
-3480 258 -127 406 -197 498 -236 32 -14 86 -37 120 -53 444 -203 1073 -437
1627 -606 102 -31 205 -62 230 -70 323 -99 1068 -273 1435 -334 33 -6 112 -19
175 -30 171 -28 301 -48 440 -66 69 -9 154 -20 190 -25 85 -13 225 -27 440
-45 96 -8 195 -17 220 -20 167 -19 683 -36 1110 -36 427 0 943 17 1110 36 25
3 124 12 220 20 334 28 744 80 1070 136 63 11 142 24 175 30 366 61 1094 231
1435 334 427 130 636 198 890 288 193 69 490 183 565 217 11 5 90 38 176 74
85 35 185 78 222 95 36 17 92 41 124 55 164 69 608 287 878 431 967 515 1878
1145 2710 1875 437 383 950 896 1361 1360 619 699 1214 1530 1671 2331 150
263 251 449 370 684 380 752 718 1595 957 2390 45 151 92 315 106 370 4 17 17
64 28 105 32 116 100 389 112 445 5 28 17 79 25 115 107 460 211 1107 269
1670 54 519 71 868 71 1490 0 624 -16 955 -71 1490 -56 551 -163 1211 -269
1670 -8 36 -19 86 -24 111 -29 140 -173 678 -238 889 -19 63 -41 135 -48 160
-43 153 -144 444 -267 775 -617 1658 -1522 3174 -2682 4495 -306 348 -833 886
-1151 1175 -1505 1370 -3207 2370 -5155 3030 -656 222 -1483 435 -2075 534
-33 6 -112 19 -175 30 -389 65 -653 98 -1085 136 -93 8 -183 17 -200 20 -62
10 -659 35 -890 37 -132 2 -386 0 -565 -3z m1065 -104 c715 -36 1306 -107
2040 -246 110 -21 418 -88 550 -119 72 -17 168 -40 215 -51 176 -42 539 -145
800 -226 2190 -683 4250 -1926 5866 -3538 579 -578 1058 -1139 1565 -1835 114
-157 463 -685 577 -872 151 -249 196 -327 383 -664 77 -140 326 -632 379 -749
12 -27 47 -104 77 -170 110 -239 195 -444 348 -835 90 -231 272 -765 336 -987
16 -57 43 -151 60 -210 68 -236 167 -634 213 -853 15 -69 31 -141 36 -160 5
-19 18 -87 30 -150 11 -63 25 -135 30 -160 22 -105 84 -495 100 -625 6 -49 13
-99 16 -110 8 -39 50 -427 69 -645 52 -586 67 -1287 41 -1840 -17 -350 -42
-685 -71 -950 -27 -251 -35 -315 -39 -335 -3 -11 -10 -60 -16 -110 -16 -130
-78 -520 -100 -625 -5 -25 -19 -97 -31 -160 -57 -310 -194 -878 -284 -1185
-15 -49 -40 -136 -56 -192 -125 -441 -451 -1317 -651 -1753 -58 -124 -87 -188
-108 -235 -32 -72 -260 -528 -302 -605 -175 -322 -230 -421 -291 -525 -431
-736 -913 -1419 -1436 -2040 -1265 -1499 -2792 -2709 -4526 -3585 -341 -172
-520 -257 -740 -350 -55 -23 -118 -51 -140 -61 -120 -54 -439 -181 -665 -264
-321 -118 -1012 -340 -1196 -384 -21 -5 -127 -31 -234 -59 -222 -56 -394 -98
-460 -111 -25 -5 -124 -25 -220 -44 -288 -59 -580 -109 -820 -142 -63 -8 -128
-18 -145 -21 -16 -3 -86 -11 -155 -19 -69 -8 -181 -21 -250 -29 -69 -8 -161
-17 -205 -21 -44 -3 -123 -10 -175 -15 -543 -54 -1717 -54 -2260 0 -52 5 -131
12 -175 15 -44 4 -136 13 -205 21 -69 8 -181 21 -250 29 -69 8 -138 16 -155
19 -16 3 -82 13 -145 21 -242 33 -507 79 -820 142 -96 20 -195 39 -220 44 -25
5 -72 16 -105 24 -671 163 -855 215 -1350 380 -355 118 -850 302 -1010 375
-11 5 -36 15 -55 24 -19 8 -62 27 -95 41 -33 15 -80 35 -105 45 -25 10 -89 39
-143 64 -55 25 -101 46 -103 46 -22 0 -777 384 -944 480 -36 21 -130 75 -210
120 -849 487 -1628 1048 -2385 1719 -505 448 -1024 980 -1461 1501 -395 471
-744 942 -1079 1460 -127 196 -361 582 -450 745 -16 28 -47 84 -70 125 -127
228 -367 705 -488 970 -393 865 -696 1747 -916 2670 -65 272 -163 755 -197
975 -8 55 -20 127 -25 160 -44 276 -96 720 -123 1065 -57 700 -57 1580 0 2280
138 1706 556 3321 1261 4870 128 281 378 776 510 1009 29 51 53 95 53 96 0 2
31 56 70 121 324 554 665 1066 1014 1524 716 941 1585 1830 2481 2541 720 571
1477 1067 2260 1482 128 67 690 347 699 347 2 0 42 17 88 39 126 59 543 235
723 306 523 206 1257 440 1735 554 47 11 144 34 215 51 132 31 441 98 550 119
603 114 1026 171 1630 221 475 40 1324 51 1840 25z"/>
					<path d="M6845 21465 c-60 -7 -123 -18 -140 -23 -16 -6 -61 -20 -100 -32 -104
-31 -302 -134 -397 -206 -159 -119 -319 -309 -411 -491 -20 -40 -37 -78 -37
-85 0 -7 -6 -24 -13 -38 -82 -157 -112 -525 -62 -755 31 -144 39 -174 59 -210
9 -16 16 -36 16 -43 0 -21 69 -151 129 -241 77 -118 215 -264 320 -339 109
-79 308 -183 386 -202 17 -4 65 -17 108 -29 112 -31 262 -44 406 -36 114 6
157 13 336 60 88 23 293 128 388 197 83 61 251 225 290 283 78 115 103 157
139 231 42 88 58 109 58 75 0 -17 -29 -80 -60 -131 -5 -8 -26 -44 -47 -80 -20
-36 -47 -78 -59 -95 -14 -21 -20 -42 -18 -70 4 -62 27 -135 42 -135 7 0 11 -4
8 -8 -3 -5 4 -15 15 -22 10 -8 19 -20 19 -27 0 -38 47 9 52 52 2 21 11 34 30
43 19 9 39 39 71 105 68 140 82 184 69 210 -6 12 -25 57 -43 100 -31 77 -47
101 -71 109 -10 4 -10 10 -1 31 24 51 52 194 64 323 11 112 10 151 -4 275 -15
132 -33 223 -56 274 -4 11 -19 47 -31 80 -62 161 -172 328 -305 460 -234 232
-514 364 -839 395 -131 12 -168 11 -311 -5z"/>
					<path d="M12575 21438 c-11 -6 -89 -73 -174 -147 -85 -75 -210 -183 -277 -241
-141 -122 -634 -548 -768 -665 -51 -44 -168 -145 -261 -225 -92 -80 -220 -190
-284 -245 -64 -55 -129 -111 -145 -125 -16 -14 -39 -34 -52 -45 -12 -11 -80
-69 -150 -130 -759 -655 -838 -725 -827 -736 4 -4 364 -10 798 -12 844 -4 786
-1 1025 -54 369 -82 679 -325 835 -653 34 -71 81 -213 89 -270 44 -294 7 -541
-116 -772 -200 -377 -555 -598 -1061 -663 -96 -12 -255 -15 -847 -15 -400 0
-735 -3 -744 -6 -14 -6 -16 -121 -16 -1204 0 -948 3 -1201 13 -1207 6 -4 442
-10 967 -13 925 -5 958 -6 1060 -27 229 -47 298 -69 470 -151 176 -84 276
-161 441 -342 66 -72 149 -224 193 -352 104 -304 79 -643 -70 -939 -91 -183
-269 -384 -436 -494 -213 -141 -482 -236 -758 -267 -164 -19 -3055 -14 -3078
4 -14 12 -15 240 -9 2108 4 1152 10 2582 14 3178 l6 1082 -22 0 c-13 0 -48
-23 -84 -55 -86 -76 -371 -321 -437 -375 -30 -25 -109 -92 -176 -150 -178
-156 -809 -696 -1115 -955 -84 -71 -176 -150 -204 -175 -27 -26 -66 -59 -85
-74 -19 -16 -62 -53 -95 -82 -33 -29 -159 -136 -280 -239 -121 -102 -228 -196
-238 -208 -17 -22 -18 -103 -22 -1567 -14 -4711 -15 -5207 -1 -5225 12 -16
177 -17 3305 -16 2702 2 3292 0 3289 -11 -3 -10 -45 -15 -154 -19 -82 -4
-1540 -7 -3241 -8 -2087 -1 -3100 -4 -3119 -11 -26 -10 -26 -12 -20 -66 7 -63
23 -99 58 -130 56 -51 -123 -48 3083 -52 2126 -2 3065 0 3250 8 143 6 348 15
455 20 107 4 251 17 320 28 69 11 175 28 235 37 290 44 679 147 935 248 193
76 522 241 656 329 110 73 131 92 114 103 -6 4 -9 13 -6 21 3 8 1 14 -5 14 -6
0 -9 7 -5 15 3 8 1 15 -4 15 -6 0 -10 7 -10 15 0 8 -4 15 -10 15 -5 0 -7 7 -4
15 4 8 1 15 -6 15 -6 0 -9 4 -6 9 3 5 0 11 -8 14 -9 4 -12 14 -9 27 7 25 24
21 39 -9 15 -30 26 -31 30 -3 2 12 0 22 -5 22 -5 0 -9 6 -8 12 1 34 -4 48 -18
48 -9 0 -18 -7 -21 -15 -4 -9 -13 -13 -24 -10 -11 4 -20 -3 -28 -20 -6 -14
-19 -25 -28 -25 -12 0 -15 -6 -10 -24 4 -17 1 -26 -10 -30 -24 -9 -31 -7 -14
4 12 8 11 10 -6 10 -12 0 -27 -7 -34 -15 -7 -8 -27 -15 -45 -15 -34 0 -120
-33 -130 -50 -3 -6 -16 -10 -28 -10 -12 0 -28 -9 -35 -20 -7 -11 -20 -20 -30
-20 -9 0 -26 -7 -36 -15 -11 -8 -27 -15 -36 -15 -9 0 -25 -9 -35 -20 -10 -11
-26 -20 -36 -20 -9 0 -30 -7 -46 -15 -74 -38 -139 -65 -154 -65 -9 0 -43 -14
-75 -31 -34 -18 -80 -32 -109 -35 l-50 -5 50 21 c28 12 64 27 82 35 17 8 35
15 40 15 17 0 174 69 333 147 224 109 392 211 609 370 160 117 455 401 586
564 198 246 360 514 475 789 47 113 136 369 160 462 7 26 16 45 21 42 13 -7
11 -58 -2 -71 -31 -31 33 -263 74 -271 22 -4 30 18 61 165 35 166 48 255 39
262 -5 3 -24 45 -43 94 -19 48 -39 86 -45 84 -15 -5 -45 -98 -45 -143 0 -39
-22 -85 -35 -72 -4 3 3 44 14 90 62 254 87 672 60 993 -55 657 -288 1285 -668
1795 -201 271 -420 490 -686 688 -94 70 -241 169 -275 185 -8 4 -31 17 -50 29
-43 26 -326 168 -336 168 -4 0 -34 13 -68 29 -34 15 -73 31 -88 35 -39 9 -35
33 10 53 223 97 459 241 632 385 100 82 342 326 411 413 83 105 214 296 257
375 93 171 165 321 218 455 34 85 44 99 44 58 0 -17 -7 -36 -15 -43 -8 -7 -15
-21 -15 -31 0 -23 -21 -69 -32 -69 -4 0 -8 -12 -8 -26 0 -15 -11 -47 -24 -73
-14 -25 -34 -69 -46 -98 -12 -28 -28 -54 -36 -57 -8 -3 -14 -14 -14 -25 0 -21
-49 -101 -62 -101 -4 0 -8 -11 -8 -24 0 -13 -7 -29 -15 -36 -8 -7 -15 -18 -15
-25 0 -6 -9 -20 -20 -30 -11 -10 -20 -23 -20 -29 -1 -6 -23 -40 -50 -76 -28
-36 -61 -82 -75 -102 -14 -20 -34 -42 -45 -48 -11 -6 -20 -16 -20 -23 0 -7
-15 -28 -33 -48 -17 -19 -63 -70 -100 -112 -38 -42 -75 -77 -83 -77 -8 0 -14
-7 -14 -15 0 -8 -4 -15 -10 -15 -10 0 -69 -58 -71 -69 -4 -30 -9 -45 -19 -51
-9 -6 -9 -9 1 -12 14 -5 4 -28 -12 -28 -6 0 -7 5 -4 10 3 6 -3 10 -14 10 -12
0 -21 -6 -21 -14 0 -7 -14 -16 -30 -20 -39 -7 -56 -47 -39 -87 6 -15 8 -33 4
-38 -3 -6 -1 -11 5 -11 7 0 10 -7 6 -15 -3 -8 -1 -15 5 -15 6 0 9 -7 5 -15 -3
-8 -1 -15 4 -15 5 0 7 -7 4 -15 -4 -8 -1 -15 6 -15 7 0 10 -7 6 -15 -7 -18 5
-20 23 -2 10 10 10 7 2 -15 -14 -38 1 -36 47 7 23 22 33 38 26 42 -11 9 -25
37 -30 66 -3 12 3 17 22 17 14 0 24 5 23 13 -3 24 1 37 12 37 8 0 8 -5 -1 -15
-10 -12 -9 -15 4 -15 11 0 14 -5 10 -12 -5 -8 0 -9 14 -6 26 7 27 5 6 -27 -20
-30 -5 -33 21 -5 10 11 22 20 27 20 18 0 131 105 210 194 90 102 250 312 274
359 9 18 26 43 39 57 41 47 190 337 238 465 9 22 31 81 50 130 50 127 115 344
133 445 8 47 24 132 34 190 31 170 40 286 40 511 0 168 -3 216 -15 237 -8 15
-26 53 -39 85 -14 31 -29 57 -35 57 -6 0 -12 -76 -15 -207 -4 -214 -16 -358
-36 -428 -7 -22 -16 -72 -20 -111 -5 -40 -13 -81 -19 -92 -6 -12 -11 -32 -11
-47 0 -14 -8 -40 -17 -58 -28 -52 -27 -10 1 105 14 57 30 137 36 178 6 41 12
82 15 90 2 8 11 87 20 175 31 326 14 627 -55 975 -136 682 -535 1348 -1062
1771 -184 149 -442 302 -663 394 -354 148 -689 229 -1077 259 -232 19 -306 20
-333 4z m3081 -4083 c-9 -14 -16 -38 -16 -54 0 -32 -31 -73 -38 -52 -4 12 5
40 45 149 l18 47 3 -33 c2 -19 -4 -44 -12 -57z m-931 -8045 c3 -6 -1 -7 -9 -4
-18 7 -21 14 -7 14 6 0 13 -4 16 -10z m-111 -89 c3 -5 -6 -11 -21 -14 -26 -5
-26 -4 -8 9 22 17 22 17 29 5z m6 -30 c0 -5 -10 -11 -22 -14 -22 -6 -22 -6 -4
8 23 17 26 18 26 6z m57 -115 c-8 -8 -17 -12 -21 -9 -3 3 2 12 12 19 22 16 29
9 9 -10z m-932 -146 c-18 -20 -65 -28 -65 -12 0 11 40 30 64 31 18 1 18 0 1
-19z m-85 -20 c0 -16 -36 -30 -76 -30 -21 0 -45 -7 -55 -16 -10 -9 -37 -18
-61 -21 -24 -3 -47 -9 -53 -13 -12 -9 -68 -23 -105 -26 -41 -4 -184 -36 -202
-45 -21 -11 -65 -11 -93 0 -15 6 -2 11 50 20 129 21 342 70 480 110 116 34
115 34 115 21z m-690 -159 c0 -13 -90 -31 -152 -31 -69 0 -52 13 35 26 95 15
117 16 117 5z m-245 -39 c-24 -21 -151 -33 -250 -24 -89 8 -94 9 -40 12 33 1
83 5 110 10 70 10 191 12 180 2z"/>
					<path d="M15400 21410 c0 -6 11 -15 24 -20 13 -5 54 -29 91 -54 36 -25 70 -46
74 -46 4 0 19 -9 32 -20 13 -11 48 -39 77 -61 28 -21 52 -43 52 -48 0 -5 -13
-14 -30 -19 -16 -6 -39 -19 -50 -30 l-21 -19 33 -44 c19 -24 94 -93 168 -152
293 -236 476 -434 667 -722 100 -149 205 -346 273 -505 17 -41 38 -89 46 -107
8 -17 14 -40 14 -51 0 -11 8 -44 19 -74 38 -109 51 -154 57 -208 3 -30 12 -72
20 -93 8 -20 14 -59 14 -85 0 -26 9 -94 20 -152 17 -89 20 -145 19 -365 0
-143 -4 -282 -10 -310 -5 -27 -13 -95 -18 -150 -5 -55 -15 -113 -22 -130 -7
-16 -15 -61 -19 -100 -6 -68 -21 -140 -42 -200 -5 -16 -14 -52 -20 -80 -5 -27
-16 -62 -24 -77 -8 -15 -14 -38 -14 -51 0 -13 -7 -32 -15 -43 -8 -10 -15 -33
-15 -50 0 -18 -8 -39 -20 -49 -11 -10 -20 -30 -20 -45 0 -15 -7 -33 -15 -40
-8 -7 -15 -20 -15 -29 0 -9 -9 -34 -20 -56 -11 -22 -20 -44 -20 -51 0 -6 -7
-17 -15 -24 -8 -7 -17 -27 -21 -46 -3 -19 -19 -52 -35 -74 -16 -22 -29 -46
-29 -54 0 -7 -9 -25 -20 -39 -11 -14 -20 -30 -20 -35 0 -5 -7 -15 -15 -22 -8
-7 -15 -18 -15 -25 0 -14 -73 -131 -113 -180 -13 -16 -31 -43 -40 -60 -8 -16
-26 -40 -39 -52 -12 -13 -41 -45 -63 -73 -68 -86 -241 -273 -302 -327 -32 -28
-85 -77 -117 -107 -33 -31 -64 -56 -68 -56 -5 0 -20 -13 -34 -29 -14 -17 -35
-33 -47 -37 -12 -3 -41 -22 -64 -40 -23 -19 -47 -34 -55 -34 -7 0 -26 -14 -43
-31 -16 -17 -42 -33 -57 -36 -16 -3 -28 -8 -28 -12 0 -4 -23 -20 -50 -36 -57
-34 -65 -50 -58 -132 5 -61 19 -78 95 -118 21 -11 49 -26 62 -34 l24 -15 -23
-6 c-54 -13 -50 -108 7 -171 36 -39 71 -62 263 -169 132 -74 298 -186 426
-288 149 -118 450 -421 562 -567 201 -259 375 -543 447 -730 40 -104 59 -145
68 -145 4 0 5 -11 1 -25 -4 -16 1 -37 15 -60 12 -20 21 -49 21 -65 0 -16 4
-31 9 -34 5 -3 11 -20 14 -38 5 -32 27 -131 48 -213 5 -22 14 -69 20 -105 10
-64 15 -108 40 -330 16 -142 17 -471 1 -605 -7 -55 -18 -154 -26 -220 -14
-125 -25 -180 -51 -278 -8 -32 -15 -66 -15 -76 0 -20 -21 -95 -50 -176 -10
-27 -28 -84 -41 -125 -12 -41 -28 -84 -35 -95 -6 -11 -15 -36 -19 -55 -4 -19
-18 -58 -31 -86 -13 -28 -24 -54 -24 -58 0 -7 -43 -98 -60 -126 -5 -8 -19 -37
-32 -65 -13 -27 -31 -56 -40 -63 -10 -7 -18 -21 -18 -31 0 -10 -7 -24 -15 -31
-8 -7 -15 -17 -15 -22 0 -5 -8 -21 -18 -36 -10 -15 -45 -67 -78 -117 -32 -49
-61 -92 -64 -95 -3 -3 -18 -24 -34 -47 -16 -24 -32 -43 -37 -43 -4 0 -11 -10
-15 -22 -4 -13 -12 -27 -19 -33 -7 -5 -31 -35 -55 -65 -49 -63 -221 -251 -265
-289 -16 -14 -55 -51 -85 -81 -30 -30 -73 -68 -97 -85 -23 -16 -66 -49 -95
-72 -29 -23 -72 -57 -96 -75 -24 -18 -50 -41 -60 -50 -9 -10 -20 -18 -24 -18
-4 0 -27 -14 -50 -31 -24 -16 -64 -44 -90 -61 -49 -32 -56 -45 -38 -76 6 -9
26 -57 44 -107 38 -105 22 -97 236 -115 211 -17 3086 -10 3117 8 15 8 24 26
29 59 4 26 4 1087 1 2358 -4 1270 -3 2612 1 2980 6 611 9 673 24 697 23 35 50
42 87 24 35 -18 72 -65 561 -701 199 -258 379 -488 400 -510 22 -22 74 -86
117 -143 43 -56 445 -578 894 -1160 449 -582 833 -1084 855 -1117 31 -48 337
-449 724 -950 65 -84 544 -706 856 -1111 144 -187 282 -362 306 -387 l43 -47
115 -10 c155 -13 3062 -13 3153 0 93 14 138 38 159 86 14 36 16 529 14 6179
l-1 6140 -44 110 c-24 61 -47 117 -51 125 -6 13 -224 15 -1858 18 l-1852 2 -5
-22 c-3 -13 -9 -1436 -13 -3163 l-7 -3140 -204 265 c-113 146 -276 357 -363
470 -153 198 -594 771 -823 1070 -61 80 -331 431 -600 780 -269 349 -977 1270
-1574 2045 -1148 1493 -1250 1624 -1290 1668 l-25 27 -1688 0 c-1146 0 -1688
-3 -1688 -10z m395 -280 c3 -5 -1 -10 -9 -10 -8 0 -18 5 -21 10 -3 6 1 10 9
10 8 0 18 -4 21 -10z m885 -1130 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z"/>
					<path d="M8370 19669 c0 -4 12 -42 26 -85 30 -87 64 -158 64 -132 0 28 -88
241 -90 217z"/>
					<path d="M8068 19145 c-2 -11 -13 -24 -25 -30 -13 -5 -23 -15 -23 -22 0 -7 -4
-13 -10 -13 -5 0 -10 -7 -10 -15 0 -8 6 -12 13 -9 8 3 20 -9 29 -29 15 -32 20
-34 60 -34 47 1 74 17 56 35 -6 6 -6 12 0 16 6 4 1 26 -14 56 -13 27 -24 54
-24 60 0 5 -11 9 -25 8 -16 -2 -25 -9 -27 -23z"/>
					<path d="M7946 19019 c-10 -32 -13 -69 -5 -69 16 0 9 -15 -11 -28 -19 -12 -22
-9 -36 25 -15 34 -17 35 -27 18 -5 -11 -15 -23 -21 -27 -7 -5 -4 -8 7 -8 14 0
17 -5 12 -21 -5 -15 -4 -19 4 -15 6 4 11 2 11 -3 0 -6 -5 -11 -11 -11 -5 0 -7
-5 -4 -10 4 -6 11 -7 17 -4 7 5 9 1 5 -9 -9 -25 6 -21 33 8 14 15 18 25 11 25
-11 0 -11 3 -1 15 7 8 18 15 24 14 7 0 6 -2 -1 -6 -20 -7 -15 -23 6 -23 12 0
25 -12 33 -30 7 -16 16 -30 20 -30 10 0 10 1 -7 49 -8 23 -15 58 -16 79 -3 40
-35 86 -43 61z"/>
					<path d="M6180 18961 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
					<path d="M7724 18859 c-4 -7 -21 -9 -45 -5 -36 6 -39 5 -39 -18 0 -23 -3 -24
-34 -19 -29 4 -35 2 -41 -17 -6 -19 -12 -21 -42 -15 -31 6 -39 2 -65 -27 -19
-21 -32 -28 -35 -20 -7 18 -37 15 -64 -6 -20 -15 -38 -18 -108 -15 -67 3 -95
-1 -140 -18 -58 -22 -62 -21 -186 7 -16 4 -69 6 -117 5 -61 -1 -88 1 -88 9 0
8 -16 11 -47 8 -42 -3 -50 0 -80 29 -38 39 -39 39 -46 12 -3 -12 -10 -18 -16
-14 -6 3 -9 11 -6 16 4 5 -3 9 -13 9 -15 0 -20 -6 -18 -25 0 -14 5 -25 10 -25
4 0 6 -7 2 -15 -3 -8 -1 -15 4 -15 4 0 11 -13 15 -30 4 -16 11 -30 15 -30 5 0
7 -7 4 -15 -4 -8 -1 -15 6 -15 9 0 10 -3 1 -14 -9 -11 -8 -15 5 -20 24 -9 34
-7 31 6 -1 6 6 14 15 16 13 2 16 -1 12 -12 -4 -10 -1 -16 7 -17 8 0 5 -4 -5
-8 -24 -9 88 -50 229 -82 84 -20 129 -23 275 -23 188 -1 273 10 405 51 131 41
256 100 295 139 13 13 13 14 -5 8 -11 -4 -25 -11 -32 -16 -7 -6 -18 -8 -25 -5
-8 2 0 12 19 23 41 24 42 35 3 28 -18 -4 -30 -2 -30 4 0 5 8 8 19 5 11 -3 21
2 24 11 5 12 1 15 -16 13 -12 -2 -16 -2 -9 0 21 7 13 25 -8 18 -26 -8 -54 17
-37 34 15 15 34 14 40 -1 3 -7 3 4 1 25 -4 36 -19 77 -29 77 -2 0 -7 -5 -11
-11z m6 -29 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m10 -30 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m-1180 -60 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5
0 9 -4 9 -10z m10 -30 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m85 -10 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10
16 10 8 0 12 -4 9 -10z m-80 -20 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6
11 10 16 10 6 0 7 -4 4 -10z m95 1 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5
0 9 -4 9 -9z m100 -15 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11
6 0 10 -2 10 -4z m-175 -16 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10
16 10 8 0 12 -4 9 -10z m85 1 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9
-4 9 -9z m-75 -31 c-3 -5 -13 -10 -21 -10 -8 0 -12 5 -9 10 3 6 13 10 21 10 8
0 12 -4 9 -10z m85 -5 c-10 -13 -9 -15 11 -15 22 0 22 0 3 -15 -18 -14 -18
-14 6 -15 14 -1 19 -4 13 -7 -19 -7 -16 -23 5 -24 14 0 14 -2 -3 -8 -23 -10
-48 6 -41 24 2 7 -2 24 -9 38 -14 27 -11 37 13 37 12 0 12 -3 2 -15z m807 -21
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-700 -20 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m710 -10 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m-630 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m-60 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m700 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-630 -10
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-64 -11 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m294 1 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m-229 -21 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1
32 -1 23 -4z m485 0 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z
m-218 -13 c4 -6 -8 -10 -29 -10 -20 0 -36 5 -36 10 0 6 13 10 29 10 17 0 33
-4 36 -10z"/>
					<path d="M7795 18860 c-4 -7 -2 -16 6 -21 18 -11 23 -4 11 17 -7 12 -11 13
-17 4z"/>
					<path d="M7910 18829 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
					<path d="M9700 18827 c0 -17 42 -138 55 -157 15 -22 87 -69 121 -79 16 -4 266
-6 555 -3 510 5 721 -2 934 -29 195 -24 392 -93 563 -196 58 -34 111 -63 118
-63 6 0 14 9 17 21 3 12 12 19 19 17 17 -6 23 -49 8 -58 -16 -10 2 -45 30 -60
11 -6 43 -42 71 -80 28 -39 55 -70 60 -70 5 0 15 -11 21 -25 13 -29 38 -33 38
-6 0 10 -11 43 -23 72 -37 85 -40 93 -38 103 1 4 -5 13 -14 20 -16 11 -35 41
-54 84 -6 12 -15 22 -19 22 -5 0 -22 18 -37 40 -15 22 -34 40 -41 40 -8 0 -14
6 -14 14 0 15 -147 153 -183 170 -6 3 -30 17 -52 31 -22 13 -69 38 -105 55
-36 17 -83 40 -105 51 -22 10 -52 19 -66 19 -14 0 -34 7 -45 15 -10 8 -39 15
-64 15 -26 0 -58 8 -77 20 -37 22 11 21 -888 21 -330 0 -642 2 -692 4 -73 4
-93 2 -93 -8z m387 -214 c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53 -2 32
-4z m1031 0 c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z"/>
					<path d="M6414 18797 c3 -17 10 -33 14 -37 4 -3 8 -16 10 -30 1 -13 8 -28 15
-32 6 -4 9 -8 5 -8 -4 0 0 -12 7 -27 10 -18 11 -29 4 -32 -10 -3 19 -30 34
-31 19 -1 -14 111 -48 164 -9 13 -13 30 -10 38 4 10 -1 17 -16 21 -19 5 -21 3
-15 -26z"/>
					<path d="M7915 18799 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
					<path d="M7839 18778 c-7 -21 -3 -48 8 -53 8 -2 11 -10 7 -16 -4 -8 -3 -9 5
-5 9 6 9 11 0 22 -6 8 -8 14 -4 14 4 0 2 6 -4 13 -6 8 -8 17 -4 20 3 4 3 7 0
7 -4 0 -7 -1 -8 -2z"/>
					<path d="M7925 18769 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
					<path d="M7936 18734 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z"/>
					<path d="M14557 15714 c3 -9 -1 -14 -12 -14 -9 0 -13 5 -10 10 3 6 -4 10 -16
10 -17 0 -20 -3 -11 -12 8 -8 8 -17 1 -30 -8 -14 -5 -27 9 -51 11 -18 25 -50
32 -73 12 -39 45 -73 58 -59 4 3 0 10 -8 16 -13 8 -11 12 10 29 14 11 28 20
32 20 3 0 -2 17 -12 38 -10 20 -23 56 -30 79 -8 24 -20 43 -31 46 -12 3 -16 0
-12 -9z m33 -93 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m5
-31 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z
m10 -30 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4
-10z"/>
					<path d="M14437 15679 c-4 -13 -10 -18 -18 -14 -8 6 -11 1 -7 -18 3 -14 1 -31
-3 -38 -5 -8 0 -18 12 -27 10 -7 19 -20 19 -29 0 -10 6 -13 16 -9 13 5 14 4 5
-7 -8 -11 -8 -23 0 -48 15 -44 14 -43 41 -28 22 12 23 11 6 -8 -36 -40 -9 -46
35 -8 31 28 33 35 11 35 -8 0 -12 5 -8 11 3 6 0 15 -7 20 -10 6 -11 9 -1 9 6
0 12 5 12 10 0 6 -7 10 -16 10 -8 0 -13 4 -10 9 9 14 -38 131 -52 131 -7 0
-10 5 -7 10 3 6 0 10 -8 10 -8 0 -17 -9 -20 -21z"/>
					<path d="M14343 15623 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
					<path d="M14311 15584 c-16 -16 -36 -24 -60 -24 -25 0 -32 -3 -22 -9 10 -7 5
-11 -18 -16 -17 -3 -31 -11 -31 -16 0 -5 -7 -9 -15 -9 -21 0 -19 -23 4 -51 17
-21 21 -22 58 -11 48 14 63 15 43 2 -13 -8 -12 -10 3 -10 11 0 17 -6 14 -12
-2 -7 7 -23 20 -36 20 -18 28 -20 46 -11 34 18 35 22 3 15 l-30 -7 25 20 c14
12 18 20 10 21 -7 0 -11 6 -7 15 3 8 1 15 -5 15 -6 0 -8 4 -4 10 3 5 -2 18
-12 30 -10 11 -19 25 -19 30 -3 21 -4 22 -17 14 -7 -5 -1 1 13 13 14 13 27 18
28 13 5 -13 39 -13 47 0 3 6 -1 10 -9 10 -19 0 -21 16 -3 23 6 3 1 5 -13 5
-14 0 -36 -11 -49 -24z m9 -98 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5
11 10 11 6 0 10 -2 10 -4z"/>
					<path d="M14362 15538 c3 -7 13 -37 22 -65 13 -40 23 -53 37 -53 24 0 25 20 2
21 -11 0 -13 3 -5 6 18 7 15 23 -5 23 -15 0 -16 2 -3 10 12 8 12 10 -4 16 -14
5 -15 9 -4 15 10 7 8 9 -6 9 -12 0 -16 5 -12 15 3 9 0 15 -10 15 -9 0 -14 -6
-12 -12z"/>
					<path d="M14629 15523 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21
-13z"/>
					<path d="M8430 15172 c0 -50 63 -202 88 -210 17 -5 16 38 -2 64 -8 11 -27 52
-42 90 -33 84 -44 98 -44 56z"/>
					<path d="M9620 13977 c0 -21 35 -117 59 -165 l21 -42 63 -1 c39 0 55 -3 43 -8
-16 -6 -17 -9 -5 -17 15 -10 1663 -18 1689 -9 13 6 12 9 -4 21 -19 14 -19 14
2 14 33 0 36 1 49 12 6 5 9 16 6 24 -4 10 3 14 26 14 31 0 42 -14 19 -23 -7
-3 0 -6 15 -6 16 -1 26 -5 22 -11 -4 -6 -12 -7 -18 -4 -8 5 -9 4 -2 -4 6 -6
19 -13 30 -15 11 -2 28 -7 37 -11 23 -10 45 3 27 15 -10 6 -5 9 16 10 l30 1
-35 11 c-35 11 -35 11 -7 15 15 2 27 7 27 12 0 5 -9 7 -20 5 -11 -2 -22 0 -25
6 -4 5 2 9 12 9 10 0 29 3 43 7 22 6 24 5 11 -10 -12 -15 -11 -17 3 -17 9 0
16 -7 16 -15 0 -8 7 -15 15 -15 9 0 12 -6 9 -15 -4 -8 -1 -15 6 -15 6 0 8 -5
5 -11 -4 -6 2 -13 14 -16 16 -4 19 -9 11 -18 -7 -8 -3 -15 16 -23 14 -7 32
-12 40 -12 8 0 38 -10 65 -21 27 -12 52 -19 56 -16 3 4 0 20 -7 37 -27 65 -16
118 17 84 32 -31 41 -11 22 44 -12 35 -35 46 -73 36 -20 -5 -25 -3 -21 7 3 8
-6 19 -22 27 -41 18 -56 16 -48 -9 4 -12 8 -27 10 -33 1 -6 9 -28 19 -48 9
-21 16 -42 16 -48 0 -5 4 -10 10 -10 5 0 7 -7 4 -15 -4 -8 -1 -15 5 -15 6 0
11 -4 11 -10 0 -14 -36 -12 -47 3 -6 6 -13 29 -16 50 -3 21 -15 58 -27 81 -26
55 -85 122 -94 107 -4 -6 -19 -11 -34 -12 l-27 0 25 -11 c24 -10 24 -10 5 -18
-17 -7 -17 -9 -2 -9 9 -1 17 -5 17 -10 0 -12 -26 -21 -61 -21 -34 0 -30 19 4
21 14 1 9 4 -13 9 -22 5 -27 8 -13 9 39 2 36 21 -4 22 -30 2 -32 3 -10 6 37 6
34 23 -4 23 -19 0 -28 4 -23 11 4 8 -14 10 -62 8 -41 -2 -78 2 -91 9 -12 7
-35 12 -52 12 -16 0 -33 5 -36 10 -4 7 -310 10 -886 10 -729 0 -879 -2 -879
-13z m1940 -53 c0 -2 -17 -4 -37 -4 -24 0 -33 3 -25 9 12 7 62 4 62 -5z m19
-30 c2 -2 -12 -4 -33 -4 -22 0 -35 4 -31 10 5 9 52 4 64 -6z m-14 -24 c4 -6
-5 -10 -20 -10 -15 0 -24 4 -20 10 3 6 12 10 20 10 8 0 17 -4 20 -10z m15 -30
c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4 25 -10z
m405 -30 c-28 -12 -37 -12 -30 0 3 6 16 10 28 9 21 0 21 -1 2 -9z m20 -20 c3
-6 -4 -10 -17 -10 -18 0 -20 2 -8 10 19 12 18 12 25 0z"/>
					<path d="M12144 13750 c2 -14 5 -26 7 -27 1 -2 2 -6 3 -10 1 -5 7 -17 13 -28
5 -11 16 -38 23 -60 7 -22 18 -43 24 -47 8 -5 7 -8 -1 -8 -23 0 -14 -19 17
-35 l30 -15 -14 32 c-8 18 -27 67 -42 108 -15 41 -36 84 -46 95 -18 20 -19 20
-14 -5z"/>
					<path d="M11728 13753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M12055 13709 c4 -12 8 -29 9 -37 2 -9 8 -18 14 -20 15 -5 15 -2 -3
42 -16 39 -31 50 -20 15z"/>
					<path d="M12080 13630 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z"/>
					<path d="M8427 12598 c-3 -13 -6 -254 -7 -537 0 -282 -2 -517 -5 -521 -2 -4 0
-23 6 -43 8 -32 14 -37 39 -37 63 0 58 -35 59 502 1 399 -1 494 -12 507 -8 9
-24 43 -37 76 -28 74 -36 83 -43 53z"/>
					<path d="M16210 11758 c0 -20 5 -49 11 -65 6 -15 10 -29 10 -30 -1 -2 9 -27
20 -55 12 -29 27 -68 34 -87 l12 -34 7 47 c5 28 3 52 -3 60 -5 6 -24 50 -41
96 -37 101 -50 118 -50 68z"/>
					<path d="M16076 11093 c14 -45 60 -142 66 -143 12 0 -46 153 -60 158 -8 2 -10
-3 -6 -15z"/>
					<path d="M15951 10819 c-1 -17 23 -90 59 -182 15 -37 27 -54 33 -48 9 9 -10
61 -45 125 -6 11 -19 42 -29 70 -9 28 -18 44 -18 35z"/>
					<path d="M15916 10718 c3 -13 13 -39 22 -58 12 -28 16 -31 19 -16 3 10 -3 36
-14 57 -20 42 -39 53 -27 17z"/>
					<path d="M15881 10670 c-1 -43 79 -246 87 -222 4 14 -13 70 -39 127 -10 22
-25 58 -33 80 -12 33 -15 35 -15 15z"/>
					<path d="M15840 10578 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10
-2z"/>
					<path d="M15810 10530 c-1 -8 -2 -21 -1 -29 2 -7 -2 -10 -8 -6 -6 3 -11 1 -11
-4 0 -6 7 -11 15 -11 18 0 20 -16 3 -23 -9 -4 -8 -6 2 -6 8 -1 21 -10 28 -21
17 -28 34 -16 22 15 -6 16 -5 25 3 29 7 3 3 7 -9 11 -11 4 -25 19 -31 33 -6
16 -12 21 -13 12z"/>
					<path d="M15860 10523 c0 -12 5 -25 10 -28 13 -8 13 15 0 35 -8 12 -10 11 -10
-7z"/>
					<path d="M15727 10355 c7 -37 52 -156 64 -169 13 -15 11 -33 -5 -39 -10 -4
-13 -1 -10 8 3 7 -6 38 -20 68 -14 29 -26 58 -26 64 0 11 -39 59 -43 52 -32
-55 -39 -80 -28 -110 13 -38 14 -49 2 -49 -4 0 -12 14 -17 32 -12 40 -34 49
-34 14 0 -17 -6 -26 -15 -26 -9 0 -20 -12 -25 -26 -9 -22 -7 -29 11 -45 11
-10 18 -23 14 -28 -3 -6 -1 -11 5 -11 7 0 10 -7 6 -15 -3 -8 -1 -15 5 -15 6 0
7 -5 3 -11 -3 -6 -1 -15 6 -19 6 -4 13 -19 13 -32 1 -16 7 -24 15 -21 7 3 12
-3 12 -13 0 -16 2 -16 9 -5 6 9 6 22 0 33 -5 10 -7 18 -3 18 3 0 1 7 -6 15 -7
8 -9 15 -5 15 4 0 2 7 -5 15 -7 8 -9 15 -4 15 5 0 4 4 -2 8 -16 10 -44 101
-34 107 10 6 33 -42 50 -103 6 -21 18 -47 28 -60 l18 -24 40 48 c61 75 78 112
64 142 -7 16 -7 27 -1 31 5 4 4 20 -4 44 -7 20 -10 37 -6 37 3 0 -1 4 -9 10
-9 6 -17 5 -22 -3 -5 -8 -8 -6 -8 6 0 9 -4 17 -8 17 -5 0 -13 12 -19 28 l-12
27 6 -30z m-47 -195 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6
0 11 -4 11 -10z m39 -89 c8 -14 7 -22 -3 -30 -10 -8 -13 -4 -13 19 0 35 3 37
16 11z"/>
					<path d="M15890 10330 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
					<path d="M15827 10203 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3
3 -9 2 -12 -2z"/>
					<path d="M15502 10078 c-7 -7 -12 -18 -12 -25 0 -7 -7 -13 -15 -13 -9 0 -15
-9 -14 -22 l0 -23 16 23 c22 32 50 28 58 -8 4 -17 10 -28 15 -25 10 6 10 55 0
55 -4 0 -11 11 -14 25 -7 28 -16 31 -34 13z"/>
					<path d="M15575 9910 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
					<path d="M15385 9891 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
					<path d="M15370 9847 c0 -7 4 -17 9 -22 4 -6 14 -31 21 -58 14 -52 25 -65 34
-42 3 8 1 15 -5 15 -6 0 -9 7 -5 15 3 8 1 15 -5 15 -6 0 -9 6 -7 12 3 7 -4 21
-14 32 -13 15 -15 23 -7 33 9 10 8 13 -5 13 -9 0 -16 -6 -16 -13z"/>
					<path d="M15277 9811 c-2 -9 0 -27 5 -39 4 -13 6 -28 2 -33 -3 -5 0 -9 6 -9 7
0 10 -7 6 -15 -3 -8 -1 -15 5 -15 6 0 9 -7 5 -15 -3 -8 -1 -15 5 -15 6 0 9 -7
5 -15 -3 -8 -1 -15 5 -15 6 0 9 -7 6 -14 -4 -10 -1 -13 9 -9 28 10 14 -25 -21
-51 -19 -15 -35 -22 -35 -17 0 6 4 11 9 11 14 0 20 38 7 44 -7 3 -6 8 4 15 13
9 13 11 -2 11 -11 0 -15 5 -12 15 4 8 1 15 -6 15 -10 0 -10 3 0 15 10 12 10
15 -3 15 -10 0 -14 6 -11 15 4 8 1 15 -5 15 -6 0 -11 -4 -11 -9 0 -5 -7 -12
-16 -15 -14 -5 -15 -4 -4 9 19 23 23 75 5 75 -8 0 -15 -4 -15 -10 0 -5 -15
-16 -33 -25 -20 -9 -40 -30 -50 -51 -18 -37 -22 -74 -9 -74 4 0 18 -34 31 -75
13 -41 28 -75 32 -75 4 0 9 -8 11 -17 3 -14 27 4 99 75 54 53 90 95 84 100
-17 12 -63 166 -55 182 6 12 2 15 -21 16 -18 1 -29 -5 -32 -15z m-47 -86 c-7
-9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z"/>
					<path d="M15039 9612 c-15 -9 -41 -34 -59 -54 -21 -25 -41 -38 -57 -38 -16 0
-27 -7 -30 -19 -3 -12 -11 -17 -19 -14 -9 3 -14 -2 -14 -16 0 -12 -4 -21 -10
-21 -5 0 -10 5 -10 10 0 6 -4 10 -10 10 -20 0 -10 -81 14 -123 13 -23 27 -56
30 -73 4 -18 17 -36 31 -44 21 -11 29 -8 87 31 36 23 72 56 82 72 16 29 16 29
-6 24 -23 -6 -23 -6 2 14 14 13 26 16 28 10 6 -18 27 -12 50 14 16 18 18 25 8
25 -8 0 -18 -4 -21 -10 -3 -5 -10 -10 -15 -10 -5 0 3 12 17 26 19 19 23 28 13
34 -7 4 -9 13 -6 19 4 6 2 11 -3 11 -6 0 -11 7 -11 15 0 8 -6 15 -12 15 -9 0
-8 3 2 10 12 8 12 10 -3 16 -9 3 -15 10 -12 14 3 5 1 11 -5 15 -6 4 -8 11 -5
16 4 5 0 16 -9 24 -17 18 -14 18 -47 -3z m11 -112 c0 -11 -7 -20 -16 -20 -8 0
-12 5 -8 11 3 6 0 15 -7 20 -10 6 -8 9 9 9 15 0 22 -6 22 -20z m-110 -250 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
					<path d="M14810 9273 c1 -35 24 -73 39 -64 11 7 10 15 -4 45 -18 38 -35 47
-35 19z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
